<template>
  <v-app>
    <v-card>
      <div class="card-header">
        <div class="card-title pt-3 px-3 d-flex justify-content-between">
          <div class="breadcrumb-left">
            <h4>New transaction</h4>
          </div>
          <div class="breadcrumb-right">
            <v-row>
              <v-col cols="6" class="pt-6">
                <v-select
                  outlined
                  dense
                  class="form-control"
                  v-model="search.type"
                  :items="user_types"
                  label="New transaction"
                  item-value="value"
                  item-text="name"
                ></v-select>
              </v-col>
              <v-col cols="6" v-if="search.type != 'other'" class="pt-6">
                <v-autocomplete
                  outlined
                  dense
                  v-model="user"
                  :items="user_list"
                  :search-input.sync="search_sync"
                  color="black"
                  item-text="full_name"
                  item-value="API"
                  label="Symbol no., name, email, phone"
                  placeholder="Start typing to search"
                  prepend-inner-icon="mdi-database-search"
                  @change="prefillUserData()"
                  return-object
                ></v-autocomplete>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>

      <div class="card-body">
        <v-row>
          <v-col cols="6">
            <div>
              <v-select
                :items="['parent', 'self', 'guardian']"
                v-model="history.paid_by"
                outlined
                dense
                label="Paid by"
              ></v-select>
              <span class="text-danger" v-if="$v.history.paid_by.$error"
                >This information is required</span
              >
            </div>

            <div class="mt-4">
              <v-text-field
                outlined
                dense
                :label="type == 'income' ? 'Payer name' : 'Payee name'"
                v-model="history.payer_name"
              >
              </v-text-field>
              <span class="text-danger" v-if="$v.history.payer_name.$error"
                >This information is required</span
              >
            </div>

            <div class="mt-4">
              <v-text-field
                outlined
                dense
                :label="type == 'income' ? 'Payer phone' : 'Payee phone'"
                v-model="history.payer_mobile"
              >
              </v-text-field>
              <span class="text-danger" v-if="$v.history.payer_mobile.$error"
                >This information is required</span
              >
            </div>

            <div class="mt-4">
              <v-text-field
                outlined
                dense
                :label="type == 'income' ? 'Payer address' : 'Payee address'"
                v-model="history.payer_address"
              >
              </v-text-field>
              <span class="text-danger" v-if="$v.history.payer_address.$error"
                >This information is required</span
              >
              <!--              <v-select :items="['parent', 'guardian', 'self']" v-model="history.payer_relation" outlined dense-->
              <!--                        label="Relation"></v-select>-->
              <!--              <span class="text-danger" v-if="$v.history.payer_relation.$error">This information is required</span>-->
            </div>
          </v-col>

          <v-col cols="6" class="text-right">
            <div>
              <v-text-field
                outlined
                dense
                type="date"
                label="Payment date"
                v-model="history.payment_date"
                style="max-width: 200px; float: right"
              >
              </v-text-field>
              <span class="text-danger" v-if="$v.history.payment_date.$error"
                >This information is required</span
              >
              <br style="width: 100%; clear: both" />
            </div>

            <div class="mt-4">
              <v-select
                label="Tax option"
                :items="tax_types"
                outlined
                dense
                v-model="tax_type"
                item-value="value"
                style="max-width: 160px; float: right"
                @change="calculateTotal"
                item-text="title"
              ></v-select>
              <br style="width: 100%; clear: both" />
            </div>

            <div class="mt-4">
              <v-select
                outlined
                dense
                :items="payment_types"
                v-model="history.payment_status"
                item-text="title"
                item-value="value"
                label="Type"
                style="max-width: 160px; float: right"
              >
              </v-select>
              <span class="text-danger" v-if="$v.history.payment_status.$error"
                >This information is required</span
              >
            </div>
          </v-col>

          <v-col cols="12">
            <table class="table">
              <thead>
                <tr class="px-3">
                  <th class="px-3 wrap-column">Transaction heading</th>
                  <th class="px-3 wrap-column">Title</th>
                  <th class="px-3 wrap-column">Quantity</th>
                  <th class="px-3 wrap-column">Amount</th>
                  <th class="px-1 text-center">Action</th>
                </tr>
              </thead>
              <tr v-for="(item, i) in history_items" :key="i" style="border: hidden">
                <td class="px-3 wrap-column">
                  {{ item.transaction_head_title }}
                </td>
                <td class="px-3 wrap-column">
                  <span v-if="history_id">{{ item.payment_type_title }}</span>
                  {{ item.title ? item.title : "-" }}
                </td>
                <td class="px-3 wrap-column">
                  {{ item.quantity }}
                </td>

                <td class="px-3 wrap-column">
                  {{ item.item_price }}
                </td>
                <td class="pr-0 text-left">
                  <span class="" @click="removeItem(i, item.id)"
                    ><i class="fas fa-trash"></i
                  ></span>
                </td>
              </tr>
              <tr v-if="history_items.length < 1">
                <td class="text-center" colspan="5">Data not available</td>
              </tr>

              <!--                  <pre>{{financial_payment_types}}</pre>-->
            </table>
            <hr />
          </v-col>

          <v-col cols="12">
            <table class="table" style="background-color: rgb(242 254 247)">
              <thead>
                <tr style="border: hidden">
                  <td colspan="6" class="pt-5"><h5>Add invoice item</h5></td>
                </tr>
              </thead>

              <tbody>
                <tr style="border: hidden; background-color: rgb(242 254 247) !important">
                  <td class="px-3 pt-6 py-5" style="width: 20%">
                    <v-select
                      v-model="history_item.financial_transaction_head"
                      placeholder="Transaction heading"
                      :items="financial_heads"
                      item-text="title"
                      outlined
                      dense
                      @change="getPaymentType($event)"
                      return-object
                    ></v-select>
                    <span
                      class="text-danger"
                      v-if="
                        !history_item.financial_transaction_head_id &&
                        $v.history_item.financial_transaction_head_id.$error
                      "
                      >This information is required</span
                    >
                  </td>

                  <td class="px-3 pt-6 py-5" v-if="!history_id" style="width: 20%">
                    <v-select
                      v-model="history_item.financial_payment_type_id"
                      placeholder="Payment type"
                      v-if="!history_id"
                      :items="financial_payment_types"
                      item-text="title"
                      item-value="id"
                      outlined
                      dense
                      @change="getPaymentTypeById(history_item.financial_payment_type_id)"
                    ></v-select>
                    <span
                      class="text-danger"
                      v-if="
                        !history_item.financial_payment_type_id &&
                        $v.history_item.financial_payment_type_id.$error
                      "
                      >This information is required</span
                    >
                  </td>

                  <td class="px-3 pt-6 py-5" style="width: 20%">
                    <v-text-field
                      v-if="history_item.financial_payment_type_id == 'other'"
                      outlined
                      dense
                      v-model="history_item.title"
                      placeholder="Title"
                    ></v-text-field>
                    <span
                      class="text-danger"
                      v-if="
                        !history_item.financial_payment_type_id == 'other' &&
                        $v.history_item.title.$error
                      "
                      >This information is required</span
                    >
                  </td>

                  <td class="px-3 pt-6 py-5" style="width: 10%">
                    <v-text-field
                      type="number"
                      min="0"
                      placeholder="Qty"
                      outlined
                      dense
                      v-model="history_item.quantity"
                    ></v-text-field>
                    <span
                      class="text-danger"
                      v-if="!history_item.quantity && $v.history_item.quantity.$error"
                      >This information is required</span
                    >
                  </td>

                  <td class="px-3 pt-6 py-5" style="width: 20%">
                    <v-text-field
                      type="number"
                      min="0"
                      outlined
                      dense
                      placeholder="Amount"
                      v-model="history_item.item_price"
                    ></v-text-field>
                    <span
                      class="text-danger"
                      v-if="!history_item.item_price && $v.history_item.item_price.$error"
                      >This information is required</span
                    >
                  </td>
                  <td class="pt-6 py-5 pr-6">
                    <v-btn
                      class="btn-primary btn-xs float-right btn text-white"
                      @click="addNewTransactionHeading(history_item)"
                    >
                      Add
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </table>
          </v-col>
          <v-col cols="12">
            <table class="table" style="background-color: rgb(240 240 240)">
              <thead>
                <tr class="pt-5" style="border: hidden">
                  <td colspan="2"><h5>Add discount</h5></td>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-if="type == 'income'"
                  style="border: hidden; background-color: rgb(240 240 240) !important"
                >
                  <div class="row mb-3 mt-4 mx-3">
                    <v-col cols="12" md="6">
                      <div @input="validateDiscountAmount" v-if="type == 'income'">
                        <v-select
                          outlined
                          dense
                          :items="['fixed', 'percentage']"
                          label="Discount type"
                          v-model="history.discount_type"
                        ></v-select>
                      </div>
                    </v-col>

                    <v-col cols="12" md="6">
                      <div v-if="history.discount_type">
                        <v-text-field
                          @blur="validateDiscountAmount"
                          v-if="history.discount_type == 'fixed'"
                          outlined
                          dense
                          label="Discount amount"
                          v-model="history.discount_amount"
                        >
                        </v-text-field>
                        <v-text-field
                          @input="validateDiscountAmount"
                          v-if="history.discount_type == 'percentage'"
                          outlined
                          dense
                          label="Discount percentage"
                          v-model="history.discount_percentage"
                        >
                        </v-text-field>
                      </div>
                    </v-col>
                  </div>
                </tr>
              </tbody>
            </table>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6"> </v-col>
          <v-col cols="6  text-right">
            <table class="table">
              <tr>
                <th class="text-right">Sub total</th>
                <td class="text-right">Rs.{{ history.sub_total || "00.00" }}</td>
              </tr>
              <tr v-if="type == 'income'">
                <th class="text-right">Discount</th>
                <td class="text-right">Rs.{{ history.discount_amount || "00.00" }}</td>
              </tr>

              <tr v-if="history.discount_amount">
                <th class="text-right">After discount</th>
                <td class="text-right">
                  Rs.{{
                    parseFloat(history.sub_total) - parseFloat(history.discount_amount) ||
                    "00.00"
                  }}
                </td>
              </tr>
              <tr>
                <th class="text-right">Tax</th>
                <td class="text-right">Rs.{{ history.gst_amount || "00.00" }}</td>
              </tr>

              <tr v-if="use_wallet_money">
                <th class="text-right">
                  Wallet <span><i class="fas fa-trash" @click="removeWallet()"></i></span>
                </th>
                <td class="text-right">
                  Rs.{{ history.deducted_from_wallet || "00.00" }}
                </td>
              </tr>
              <tr>
                <th class="text-right">Total</th>
                <td class="text-right">
                  <span style="font-size: 18px; font-weight: bold">
                    Rs.{{ history.total_amount || "00.00" }}</span
                  >
                </td>
              </tr>
            </table>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <div
              class="mt-6 px-4 py-5 rounded"
              style="background-color: rgb(240 240 240) !important"
            >
              <v-textarea
                rows="4"
                outlined
                dense
                label="Remark"
                v-model="history.remarks"
              >
              </v-textarea>
            </div>
          </v-col>

          <v-col cols="12">
            <v-row v-if="current_balance && type == 'income'">
              <v-col cols="6" style="float: right">
                <span class="text-danger"
                  >Rs. {{ current_balance }} available in wallet</span
                >
              </v-col>
            </v-row>
            <v-row v-if="type == 'income'">
              <v-col cols="7"></v-col>
              <v-col cols="5" class="text-right">
                <v-switch
                  v-model="use_wallet_money"
                  @click="useWalletMoney()"
                  label="Use from wallet"
                ></v-switch>
              </v-col>
              <v-col cols="7"></v-col>
              <v-col cols="5" class="text-right">
                <v-text-field
                  @input="calculateReturnCash"
                  outlined
                  dense
                  type="number"
                  label="Cash received"
                  v-model="history.received_cash"
                >
                </v-text-field>
                <span class="text-danger" v-if="$v.history.received_cash.$error"
                  >This information is required</span
                >
              </v-col>
            </v-row>
            <v-row v-if="type == 'income'">
              <v-col cols="7">
                <v-card
                  v-if="history.payment_method == 'bank' && paymentDetail"
                  class="mx-auto"
                  max-width="500"
                >
                  <v-card-text>
                    <p class="text-h4 text--primary">
                      {{ paymentDetail.title }}
                    </p>
                    <p class="font-weight-medium">{{ paymentDetail.bank_branch_name }}</p>
                    <div class="text--primary">
                      <span class="font-weight-bold h6">
                        {{ paymentDetail.bank_account_number }}
                      </span>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="5" class="text-right">
                <div style="background-color: #f8ebd5" class="px-4 py-5 rounded">
                  <v-row>
                    <v-col cols="12">
                      <v-switch
                        v-model="use_as_advance"
                        label="Paying in advance"
                        @change="advanceCashReceived"
                      ></v-switch>

                      <div class="mt-4">
                        <v-text-field
                          outlined
                          v-if="use_as_advance"
                          dense
                          readonly
                          type="number"
                          label="Total advance amount "
                          v-model="history.advance_received"
                        >
                        </v-text-field>
                      </div>

                      <div class="mt-4">
                        <v-select
                          outlined
                          dense
                          @change="changePaymentType"
                          :items="payment_method"
                          v-model="history.payment_method"
                          item-text="title"
                          item-value="value"
                          label="Payment method"
                        >
                        </v-select>
                        <span class="text-danger" v-if="$v.history.payment_method.$error"
                          >This information is required</span
                        >
                      </div>
                    </v-col>
                  </v-row>
                  <v-row v-if="history.change_return">
                    <v-col cols="5" class="pt-7">
                        <span style="font-size: 1.5rem !important; color: red; margin-top: 10px;" >Change</span
                      ></v-col
                    >
                    <v-col cols="7" class="text-right" style="background-color: #f8ebd5">
                      <h1 style="color: red; font-size: 4rem !important">
                        {{ history.change_return }}
                      </h1>
                    </v-col>
                  </v-row>
                </div>
              </v-col>

            </v-row>
          </v-col>
        </v-row>

        <div class="row">
          <div class="col-12">
            <!--            <v-btn-->
            <!--                class="text-gray  ml-2  btn btn-standard float-right"-->
            <!--                depressed-->
            <!--            >Cancel-->
            <!--            </v-btn>-->
            <v-btn
              :disabled="disable_save"
              class="btn btn-primary text-white float-right"
              @click="validateForm()"
            >
              Save
            </v-btn>
          </div>
        </div>

      </div>
    </v-card>
  </v-app>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import FinanceMixin from "@/mixins/FinanceMixin";
import FilterMixin from "@/mixins/FilterMixin";
import UserService from "@/core/services/user/UserService";
import FinancialPaymentTypeService from "@/core/services/finance/finance-payment-type/FinancialPaymentTypeService";
import FinancialHistoryService from "@/core/services/finance/history/FinancialHistoryService";
import FinancialHistoryItemService from "@/core/services/finance/history/FinancialHistoryItemService";
import PaymentGatewaySettingService from "@/core/services/site-setting/PaymentGatewaySettingService";

const paymentGatewaySetting = new PaymentGatewaySettingService();
const paymentTypeService = new FinancialPaymentTypeService();
const userService = new UserService();
const historyService = new FinancialHistoryService();
const itemService = new FinancialHistoryItemService();

export default {
  name: "NewTransaction",
  mixins: [FinanceMixin, FilterMixin],
  validations() {
    return {
      history: this.history_rules,
      history_item: this.history_item_rule,
    };
  },
  data() {
    return {
      descriptionLimit: 60,
      entries: [],
      history_items: [],
      isLoading: false,
      use_as_advance: false,
      disable_save: false,
      use_wallet_money: false,
      user: {},
      history: {
        payment_status: null,
        payment_method: null,
        payment_date: null,
        payer_name: null,
        payer_mobile: null,
        payer_address: null,
        payer_relation: null,
        paid_by: null,
        is_full_paid: 1,
        discount_amount: null,
        type: null,
        discount_type: null,
        discount_percentage: null,
        change_return: null,
        received_cash: null,
        deducted_from_wallet: null,
      },
      history_item: {
        financial_transaction_head_id: null,
        financial_payment_type_id: null,
        title: null,
        quantity: null,
        item_price: null,
      },
      search: { type: "student" },
      search_sync: null,
      tax_types: [
        { title: "Tax Inc  lusive", value: "inclusive" },
        { title: "Tax Exclusive", value: "exclusive" },
        { title: "No Tax", value: "no" },
      ],
      tax_type: "inclusive",
      user_types: [
        { name: "Student", value: "student" },
        { name: "Teacher", value: "teacher" },
        { name: "Staff", value: "staff" },
        { name: "Other", value: "other" },
      ],
      payment_types: [
        {
          title: "Quote",
          value: "pending",
        },
        {
          title: "Invoice",
          value: "paid",
        },
      ],
      paymentDetail: null,
      payment_method: [
        {
          title: "Bank",
          value: "bank",
        },
        {
          title: "Cash",
          value: "cash",
        },
        {
          title: "Khalti",
          value: "khalti",
        },
        {
          title: "Esewa",
          value: "esewa",
        },
        {
          title: "Connect Ips",
          value: "connect_ips",
        },
      ],
      // items: [
      //   {
      //     title: 'Bank',
      //     value: 'bank'
      //   },
      //   {
      //     title: 'Cash',
      //     value: 'cash'
      //   },
      //
      // ]
    };
  },
  computed: {
    type() {
      return this.$route.params.type;
    },
    fields() {
      if (!this.model) return [];
      return Object.keys(this.model).map((key) => {
        return {
          key,
          value: this.model[key] || "n/a",
        };
      });
    },
    user_list() {
      return this.entries.map((entry) => {
        return Object.assign({}, entry);
      });
    },
    history_id() {
      return this.$route.params.id;
    },
    history_rules() {
      let rule = {
        payment_status: { required },
        payment_method: { required },
        payment_date: { required },
        payer_name: { required },
        payer_mobile: { required },
        payer_address: { required },
        payer_relation: { required },
        paid_by: { required },
        // 'received_cash': {required},
      };
      if (this.type == "income") {
        rule.received_cash = { required };
      }
      return rule;
    },
    history_item_rule() {
      let rule = {
        financial_transaction_head_id: { required },
        financial_payment_type_id: { required },
        quantity: { required },
        item_price: { required },
      };
      return rule;
    },
  },
  watch: {
    search_sync(val) {
      // if (this.user_list.length > 0) return
      // this.isLoading = true
      this.search.name = val;
      userService
        .paginate(this.search)
        .then((response) => {
          this.entries = response.data.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },
  },
  mounted() {
    this.history.type = this.type;
    this.getAllActiveTransactionHead(this.type);
    this.getSetting();
  },
  methods: {
    prefillUserData() {
      this.history.payer_name = this.user.full_name;
      this.history.payer_mobile = this.user.phone;
      this.history.payer_email = this.user.email;
      this.history.payer_user_id = this.user.id;
    },
    addNewTransactionHeading(item) {
      this.$v.history_item.$touch();
      if (this.$v.history_item.$error) {
        setTimeout(() => {
          this.$v.history_item.$reset();
        }, 3000);
      } else {
        let exist = false;
        if (this.history_items.length > 0) {
          this.history_items.forEach((ele) => {
            if (
              item.financial_payment_type_id == ele.financial_payment_type_id &&
              item.financial_transaction_head_id == ele.financial_transaction_head_id
            ) {
              this.$snotify.error("Item already exist in list");
              exist = true;
            }
          });
        }
        if (!exist) {
          this.history_items.push(item);
        }
        this.resetHistoryItem();
      }
    },
    resetHistoryItem() {
      this.history_item = {
        financial_transaction_head_id: null,
        financial_payment_type_id: null,
        title: null,
        quantity: null,
        item_price: null,
      };
      this.calculateTotal();
      this.$v.history_item.$reset();
    },
    removeItem(index, id = null) {
      this.$confirm({
        message: `Are you sure you want to delete this item?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            if (id) {
              itemService.delete(id).then((response) => {
                this.$snotify.success("Item Information deleted");
              });
            }
            this.history_items.splice(index, 1);
            this.calculateTotal();
          }
        },
      });
    },
    getHistory() {
      historyService.show(this.history_id).then((response) => {
        this.history = response.data.history;
        this.history_items = this.history.items;
        this.calculateTotal();
        this.getUserDetail();
      });
    },
    validateDiscountAmount() {
      this.removeWallet();
      this.removeCashReturn();
      if (
        this.history.discount_type == "fixed" &&
        parseFloat(this.history.discount_amount) < parseFloat(this.history.sub_total)
      ) {
        this.calculateTotal();
      } else if (this.history.discount_type == "percentage") {
        this.calculateTotal();
      } else {
        this.history.discount_amount = 0;
        this.$snotify.error("Discount amount cannot be more than actual amount");
        this.calculateTotal();
      }
    },
    calculateTotal() {
      let subtotal = 0;
      if (this.history_items.length < 0) return;
      let taxPercentage = this.setting.tax_percentage;
      this.history_items.forEach((ele) => {
        subtotal += parseFloat(ele.item_price);
      });
      this.history.sub_total = subtotal;
      let walletAmount = this.history.deducted_from_wallet;
      walletAmount = walletAmount ? parseFloat(walletAmount) : 0;
      if (this.history.discount_amount && this.history.discount_type == "fixed") {
        subtotal = parseFloat(subtotal) - parseFloat(this.history.discount_amount);
      }
      if (
        this.history.discount_percentage &&
        this.history.discount_type == "percentage"
      ) {
        this.history.discount_amount =
          (parseFloat(subtotal) * parseFloat(this.history.discount_percentage)) / 100;
        subtotal = parseFloat(subtotal) - parseFloat(this.history.discount_amount);
      }

      switch (this.tax_type) {
        default:
          this.history.gst_amount = 0;
          this.history.gst_percentage = null;
          break;

        case "inclusive":
          this.history.gst_amount =
            parseFloat(subtotal) * (parseFloat(taxPercentage) / 100);
          this.history.gst_percentage = taxPercentage;
          break;
      }
      this.history.gst_amount = Number(this.history.gst_amount).toFixed(2);
      this.history.discount_amount = Number(this.history.discount_amount).toFixed(2);
      this.history.sub_total = Number(this.history.sub_total).toFixed(2);
      this.history.total_amount =
        parseFloat(subtotal) + parseFloat(this.history.gst_amount);
      this.history.total_amount = Number(this.history.total_amount).toFixed(2);
      let total = parseFloat(this.history.total_amount);
      if (walletAmount) {
        if (walletAmount < total) this.history.total_amount = total - walletAmount;
        else {
          this.history.total_amount = 0;
          this.history.received_cash = 0;
          this.history.change_return = walletAmount - total;
        }
      }
      this.calculateReturnCash();
    },
    validateForm() {
      this.$v.history.$touch();
      if (this.$v.history.$error) {
        setTimeout(() => {
          this.$v.history.$reset();
        }, 3000);
      } else {
        this.history.items = this.history_items;
        if (this.history_id) {
          this.updateHistory();
        } else {
          this.saveHistory();
        }
      }
    },
    saveHistory() {
      historyService.store(this.history).then((response) => {
        this.redirectTo();
      });
    },
    updateHistory() {
      historyService.update(this.history_id, this.history).then((response) => {
        this.redirectTo();
      });
    },
    redirectTo() {
      this.$snotify.success("Information updated");
      this.$tabs.close().then((response) => {
        this.$router.push({ name: "finance-history" });
      });
    },
    calculateReturnCash() {
      this.history.change_return = 0;
      this.disable_save = false;
      if (this.type == "expense") return;
      let total = this.history.total_amount ? this.history.total_amount : 0;
      let received = this.history.received_cash ? this.history.received_cash : 0;
      let wallet = this.history.deducted_from_wallet
        ? this.history.deducted_from_wallet
        : 0;
      if (parseFloat(received) < parseFloat(total)) {
        // this.$snotify.error("Received amount cannot be less than total amount")
        this.disable_save = true;
        return true;
      }
      let difference = parseFloat(parseFloat(received) - parseFloat(total));
      difference = Number(difference).toFixed(2);
      this.history.change_return = Math.abs(difference);
      this.advanceCashReceived();
    },

    advanceCashReceived() {
      if (this.use_as_advance && this.history.change_return) {
        this.history.advance_received = this.history.change_return;
        this.history.change_return = 0;
      }
      if (!this.use_as_advance && this.history.advance_received) {
        this.history.change_return = this.history.advance_received;
        this.history.advance_received = 0;
      }
    },
    useWalletMoney() {
      // this.use_wallet_money = !this.use_wallet_money
      if (this.use_wallet_money) {
        if (parseFloat(this.current_balance) < parseFloat(this.history.total_amount))
          this.history.deducted_from_wallet = this.current_balance;
        else {
          let totalAmount = parseFloat(this.history.total_amount);
          this.history.deducted_from_wallet = totalAmount;
          // this.history.received_cash = 0
          // this.history.change_return = parseFloat(this.current_balance) - totalAmount
        }
      } else this.history.deducted_from_wallet = 0;
      this.calculateTotal();
    },
    removeWallet() {
      this.history.deducted_from_wallet = 0;
      this.history.change_return = 0;
      this.use_wallet_money = false;
      this.calculateTotal();
    },

    removeCashReturn() {
      this.history.advance_received = 0;
      this.history.change_return = 0;
      this.use_as_advance = false;
      this.calculateTotal();
    },
    changePaymentType() {
      this.paymentDetail = null;
      if (this.history.payment_method == "bank") {
        paymentGatewaySetting
          .findByType(this.history.payment_method)
          .then((response) => {
            this.paymentDetail = response.data.setting;
          })
          .catch((err) => {})
          .finally(() => {});
      }
    },
  },
};
</script>

<style scoped></style>
